var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-navbar',{staticClass:"sp-header px-4 py-0 align-items-stretch",attrs:{"type":"light","variant":"white","toggleable":"xl","sticky":""}},[_c('b-navbar-brand',{staticClass:"px-3 d-flex align-items-center",attrs:{"href":"/"}},[_c('sp-icon',{staticClass:"sp-navbar-logo py-1"}),_c('span',{staticClass:"sp-navbar-text text-primary"},[_vm._v(_vm._s(_vm.$t('app.title')))])],1),_c('b-navbar-toggle',{staticClass:"sp-navbar-toggler",attrs:{"target":"nav-collapse"},scopedSlots:_vm._u([{key:"default",fn:function({ expanded }){return [(expanded)?_c('b-icon',{attrs:{"icon":"x"}}):_c('b-icon',{attrs:{"icon":"list"}})]}}])}),_c('b-collapse',{class:{ visible: _vm.collapseVisible },attrs:{"id":"nav-collapse","is-nav":""},on:{"hidden":function($event){_vm.collapseVisible = false},"show":function($event){_vm.collapseVisible = true}}},[_c('b-navbar-nav',[_vm._l((_vm.navigation),function(route,idx){return [(route.children)?_c('b-nav-item-dropdown',{key:`route-${idx}`,class:{
            'sp-active': _vm.isActive(route),
          },attrs:{"text":route.title}},_vm._l((route.children),function(subRoute,subIdx){return _c('b-dropdown-item',{key:`subroute-${subIdx}`,attrs:{"active":_vm.isActive(subRoute),"to":subRoute.path}},[_vm._v(_vm._s(subRoute.title))])}),1):_c('b-nav-item',{key:idx,attrs:{"href":route.path,"link-classes":{
            'd-flex': true,
            'h-100': true,
            'align-items-center': true,
            'sp-active': _vm.isActive(route),
          }}},[_vm._v(_vm._s(route.title))])]}),_c('WalletConnector',{staticClass:"px-3 py-2 d-flex h-100 align-items-center",attrs:{"variant":"outline-primary","noModal":false}})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }